
import { EventBus } from '@/plugins/eventBus'
import PermissionMixin from '@/mixins/PermissionMixin.vue'
import PositionsAutocompleteMixin from '@/mixins/PositionsAutocompleteMixin.vue'
import SelectSpace from "@/components/SelectSpace.vue"
import { state } from '@/plugins/state'
import axios, { AxiosError, AxiosResponse } from 'axios'
import Vue, { VueConstructor } from 'vue'
import { DataTableHeader } from 'vuetify'

export interface Offer {
  "@attributes": {
    offer_KeyID: string,
    status: string
  },
  jobBoards: {
    jobBoard: string
  }, 
  general_informations: {
    posting_date: string,
    job_reference: string,
    job_title: string
  }
  company: {
    company_name: string,
    company_id: string, 
    user: string
  }
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof PermissionMixin> & InstanceType<typeof PositionsAutocompleteMixin>>).extend({
  components: { SelectSpace },
  name: 'Multiposting',
  mixins: [
    PermissionMixin,
    PositionsAutocompleteMixin,
  ],
  data: () => ({
    loading: {
      create: false, // loading state for order creation
      actions: false, // loading state for all actions
      offers: false, // loading state for offers list
    } as {[key: string]: boolean},
    search: "", // search offer on datatable
    selectedOffer: null as Offer|null, // view offer data
    dialog_create: false,
    dialog_view: false,
    dialog_delete: false,
    userJobBoards: [],
    allActiveOffers: [] as Array<Offer>, // offers loaded in datatables
    invalidCredentials: false, // If TalentPlug replied invalid credentials
    url: null, // url use on creation iframe
  }),
  computed: {
    headers: function(): Array<DataTableHeader> {
      return [
        { text: this.$vuetify.lang.t('$vuetify.offer_title'), value: 'general_informations.job_title' },
        { text: this.$vuetify.lang.t('$vuetify.status'), value: '@attributes.status' },
        { text: this.$vuetify.lang.t('$vuetify.published_at'), value: 'general_informations.posting_date' },
        { text: this.$vuetify.lang.t('$vuetify.jobboards'), value: 'jobBoards.jobBoard' },
        { text: this.$vuetify.lang.t('$vuetify.action'), value: 'actions' }
      ]
    },
    getOffersUrl: function(): string {
      let tmp = '/v1/multiposting'
      if (state.agency !== null) {
        tmp += `?space_id=${state.agency}`
      }
      return tmp
    },
    canUseMultiposting: function(): boolean {
      return this.hasPermission('multiposting') && this.invalidCredentials === false
    },
    spaceName: function(): string {
      return state.spaceName ?? ""
    },
    iframeHeigth: function(): string {
      return this.$vuetify.breakpoint.height - 61 + 'px'
    }
  },
  watch: {
    getOffersUrl() {
      this.getOffers()
    }
  },
  methods: {
    /**
     * Delete and offer
     * @param offer
     */
    deleteOffer(offer: Offer) {
      this.loading.actions = true

      let xml
      if (offer.company.company_id) {
        xml = `<jobs>
            <job>
              <offerkey_ID>${offer['@attributes'].offer_KeyID}</offerkey_ID>
              <action_name><![CDATA[Delete]]></action_name>
              <entrepriseID>${offer.company.company_id}</entrepriseID>
            </job>
          </jobs>`
      } else {
        xml = `<jobs>
            <job>
              <offerkey_ID>${offer['@attributes'].offer_KeyID}</offerkey_ID>
              <action_name><![CDATA[Delete]]></action_name>
            </job>
          </jobs>`
      }
      
      axios.get('/v1/multiposting/SendToUbipostingLite', { params: { xml: xml }})
      .then((response: AxiosResponse) => {
        let errors = Object.keys(response.data).filter((eachKey) => /error_/.test(eachKey));
        if (errors.length > 0) {
          EventBus.$emit('snackbar', { message: errors.join(), color: 'error' })
        } else {
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.offer_delete_success'), color: 'success' }) // display success
          this.dialog_delete = false // hide modal
          this.getOffers() // reload offers
        }
      })
      .catch((e: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: e })
      })
      .finally(() => {
        this.loading.actions = false
      })
    },
    /**
     * Republish an offer and reload list
     * @param offer
     */
    republishOffer(offer: Offer) {
      this.loading.actions = true

      let xml
      if (offer.company.company_id) {
        xml = `<jobs>
            <job>
              <offerkey_ID>${offer['@attributes'].offer_KeyID}</offerkey_ID>
              <action_name>republish</action_name>
              <entrepriseID>${offer.company.company_id}</entrepriseID>
              <force>true</force>
            </job>
          </jobs>`
      } else {
        xml = `<jobs>
            <job>
              <offerkey_ID>${offer['@attributes'].offer_KeyID}</offerkey_ID>
              <action_name>republish</action_name>
              <force>true</force>
            </job>
          </jobs>`
      }
      
      axios.get('/v1/multiposting/SendToUbipostingLite', { params: { xml }})
      .then((response: AxiosResponse) => {
        let errors = Object.keys(response.data).filter((eachKey) => /error_/.test(eachKey));
        if (errors.length > 0) {
          EventBus.$emit('snackbar', { message: errors.join(), color: 'error' })
        } else {
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.offer_republish_success'), color: 'success' })
          this.getOffers()
        }
      })
      .catch((e: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: e })
      })
      .finally(() => {
        this.loading.actions = false
      })
    },
    /**
     * Display offer details
     */
    viewOffer(offer: Offer) {
      this.selectedOffer = offer
      this.dialog_view = true
    },
    /**
     * Retrieve activate offers from the last 2 months
     */
    getOffers() {
      this.loading.offers = true
      this.allActiveOffers = []

      axios.get(this.getOffersUrl)
        .then((response: AxiosResponse) => {
          let tmp: Array<Offer> = [] 
          response.data.map((offerDb: {offer: Offer}) => {
            tmp.push(offerDb.offer)
          })
          this.allActiveOffers = tmp
        })
        .catch((error: AxiosError) => {
          this.invalidCredentials = true
        })
        .finally(() => {
          this.loading.offers = false
        })
    },
    /**
     * Create an offer from position
     */
    createOffer() {
      if (!this.selectedPositions) {
        return
      }
      
      this.loading.create = true
      axios
        .post(`/v1/multiposting/${this.selectedPositions}`)
        .then((response: AxiosResponse) => {
          this.url = response.data
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
        .finally(() => {
          this.loading.create = false
        })
    },
    closeCreateOfferModal() {
      this.dialog_create = false // hide modal
      this.url = null // reset iframe url
      this.selectedPositions = null // reset selected position
      this.getOffers() // refresh offers
    }
  },
  mounted() {
    this.getOffers()
  }
})
